/**
 * customer_renew
 * 
 */

 import request from '@/utils/request'

 // 
 export const customerRenewList = (data) => {
     return request({
         method: 'post',
         url: '/customer_renew/list',
         data
     })
 }

 